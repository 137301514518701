import React, { useState } from "react";
import Login from "../../components/Login";
import background from "../../assets/img/background.png"; //Background
import backgroundMobile from "../../assets/img/backgroundmobile.png";
import logo from "../../assets/img/logo.png"; //Logo
import Carousel from "../../components/Carousel";
import Data from "../../components/Data";
import Footer from "../../components/Footer";
import DataMobile from "../../components/Data/mobile";


// CSS
import "./style.css";


function Index(props) {
  const appName = "Login";
  const [showLogin, setShowLogin] = useState(false);
  const deskripsi = "Selamat datang kembali di";
  const title1 = "Madrasah Jateng Blended Learning";
  const slogan = '"Jika orang berpendidikan belum tentu sukses, maka orang tanpa pendidikan akan susah mencapai kesuksesan."';
  const copyright = "2021 Madrasah Jateng Blended Learning - Didukung oleh";

  return (
    <>
      {/*<div style={{position:'sticky', top: 0, zIndex: 99, backgroundColor: '#fff', padding: 8, width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
        <a href="/pengumuman-ppdb/" >Informasi Pengumuman Penerimaan Siswa/i Silahkan klik disini</a>
    </div> */}
      <div className="col-12 hero-container">
        <div
          style={{
            backgroundImage: `linear-gradient(171.31deg, rgba(0, 0, 0, 0.55) 81.78%, rgba(255, 255, 255, 0) 100%), url(${background})`,
          }}
          className="hero-background col-12"
        ></div>
        <div
          style={{
            backgroundImage: `linear-gradient(171.31deg, rgba(0, 0, 0, 0.55) 83.78%, rgba(255, 255, 255, 0) 100%), url(${backgroundMobile})`,
          }}
          className="hero-background hero-bg-mobile col-12"
        ></div>
        <div
          className="col-12 hero"
          style={{
            backgroundPosition: "0 100%",
            backgroundRepeat: "no-repeat",
            backgroundImage: `linear-gradient(171.31deg, rgba(0, 0, 0, 0.15) 83.78%, rgba(255, 255, 255, 0) 95.16%), url(${props.overlay})`,
          }}
        >
          <div className="">
            <div className="text-light" data-page={appName} style={{}}>
              <div
                className="title-mobile"
                style={{ padding: "20px 20px 5px 20px" }}
              >
                <br></br>
                <br></br>
                <br></br>
                <img
                  src={logo}
                  alt={appName}
                  style={{ width: 110, height: 110}}
                />
                <br></br>
                <br></br>
                <h1 style={{ fontSize: "200%" }}>{title1}</h1>
                <i style={{ fontSize: "90%" }}>{slogan} </i>
                <br></br>
                <br></br>

                <div
                  className="login"
                  style={{
                    display: "inline-table",
                    width: "5px",
                    padding: "5px 30px 5px 30px",
                    cursor: "pointer",
                    background: "#4caf50",
                    borderRadius: 20,
                  }}
                  onClick={() => setShowLogin(true)}
                >
                  Login
                </div>
              </div>
              <div className="header" style={{}}>
                <div style={{ flex: 1 }}></div>
                <div
                  className="login"
                  style={{
                    padding: "0 40px 0 40px",
                    cursor: "pointer",
                    background: "#4caf50",
                    borderRadius: 50,
                  }}
                  onClick={() => setShowLogin(true)}
                >
                  Login
                </div>
              </div>

              <div className="title-container">
                <div id="desktop">
                  <img
                    src={logo}
                    alt={appName}
                    style={{
                      width: 110,
                      height: 110,
                      marginLeft: "6%",
                      marginBottom: "2%",
                    }}
                  />

                  <h2 style={{ fontSize: "180%", marginLeft: "6%" }}>
                    {deskripsi}
                  </h2>

                  <h1 style={{ fontSize: "300%", marginLeft: "6%" }}>
                    {title1}
                  </h1>

                  <div className="slogan">
                    <p
                      style={{
                        fontSize: "170%",
                        marginLeft: "6%",
                        marginRight: "27%",
                        textAlign: "justify",
                        marginTop: 50,
                      }}
                    >
                      {slogan}{" "}
                      
                    </p>
                  </div>
                </div>
                <div className="data-container mb-5">
                  <Data />
                  <Carousel />
                  <DataMobile />
                </div>
                <Footer copyright={copyright} />
              </div>
            </div>
          </div>

          {showLogin && (
            <div
              onClick={(e) => setShowLogin(false)}
              style={{
                position: "fixed",
                backdropFilter: "blur(4px)",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ maxWidth: "600px", width: "100%" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Login />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
